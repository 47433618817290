import React, { useEffect, useRef, useState, FC } from 'react';
import './sidebar.styles.scss';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import arrowLeft from '../../../assets/icons/sidebar/arrow-left.svg';
import arrowRight from '../../../assets/icons/sidebar/arrow-right.svg';
import logo from '../../../assets/logos/deploi-logo-white.svg';
import logoSmall from '../../../assets/logos/deploi-logo-sm-white.svg';
import logout from '../../../assets/icons/sidebar/logout.svg';
import addStaff from '../../../assets/icons/sidebar/add-staff.svg';
import taskIcon from '../../../assets/icons/sidebar/task-list.svg';
import taskIconBlue from '../../../assets/icons/sidebar/task-list-blue.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarOpen } from '../../../redux/Dashboard/dashboard.actions.js';
import SidebarBucketContainer from '../sidebar-buckets/sidebar-buckets.component';
import { BucketState, BucketData } from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import { ILinkState } from '../../../types/interfaces/misc.interface';
import settingsBlue from '../../../assets/icons/sidebar/settings-blue.svg';
import settings from '../../../assets/icons/sidebar/settings.svg';
import addStaffBlue from '../../../assets/icons/sidebar/add-staff-blue.svg';
import SearchContainer from '../../general/search-container/search-container.component';
import searchIcon from '../../../assets/icons/search-white.svg';
import { useNavigate } from 'react-router-dom';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	handleLogout: (event: React.MouseEvent<HTMLDivElement>) => void;
	state: BucketState;
	handleSelectBucket: (bucket: BucketData) => void;
	handleAddStaff: (event: React.MouseEvent<HTMLDivElement>) => void;
	handleOpenOrganization: (event: React.MouseEvent<HTMLDivElement>) => void;
	handleOpenTasks: (event: React.MouseEvent<HTMLDivElement>) => void;
	selectedState: ILinkState;
};

const Sidebar: FC<Props> = ({
	handleLogout,
	state,
	handleSelectBucket,
	handleAddStaff,
	handleOpenTasks,
	handleOpenOrganization,
	selectedState,
}) => {
	const navigate = useNavigate();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const role = useSelector((state: RootState) => state.auth.role);
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.auth.user);

	const sidebarIsOpen = useSelector(
		(state: RootState) => state.dashboard.sidebarIsOpen,
	);
	const sidebarRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (sidebarIsOpen !== null) {
			setIsLoaded(true);
		}
	}, [sidebarIsOpen]);

	useEffect(() => {
		if (window.innerWidth > 1200) {
			dispatch(setSidebarOpen(true));
		} else {
			dispatch(setSidebarOpen(false));
		}
	}, []);

	useEffect(() => {
		if (sidebarRef && sidebarRef.current) {
			if (sidebarIsOpen) {
				sidebarRef.current.style.width = '350px';
				sidebarRef.current.style.minWidth = '350px';
			} else {
				sidebarRef.current.style.width = '100px';
				sidebarRef.current.style.minWidth = '100px';
			}
		}
	}, [sidebarIsOpen, sidebarRef, isLoaded]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1200) {
				dispatch(setSidebarOpen(true));
			} else {
				dispatch(setSidebarOpen(false));
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleToggleSidebar = () => {
		dispatch(setSidebarOpen(!sidebarIsOpen));
	};

	const handleOpenSearch = () => {
		dispatch(setSidebarOpen(true));
	};

	return (
		<>
			{isLoaded && (
				<div className='sidebar-container' ref={sidebarRef}>
					{sidebarIsOpen && user.name ? (
						<div className='sidebar-header-large'>
							<img
								src={logo}
								alt='logo'
								onClick={() => {
									return navigate('/check');
								}}
							/>
							<div className='avatar'>{user.name[0]}</div>
						</div>
					) : (
						<div className='sidebar-header-small'>
							<img
								src={logoSmall}
								alt='logo-small'
								onClick={() => {
									return navigate('/check');
								}}
							/>
						</div>
					)}
					{role !== 'ambassador' && (
						<div className='search-container'>
							{sidebarIsOpen ? (
								<SearchContainer />
							) : (
								<div className='small-search'>
									<TooltipLg title='Search'>
										<img
											src={searchIcon}
											alt='search'
											onClick={handleOpenSearch}
										/>
									</TooltipLg>
								</div>
							)}
						</div>
					)}
					{role !== 'ambassador' && (
						<SidebarBucketContainer
							handleSelectBucket={handleSelectBucket}
							state={state}
							sidebarIsOpen={sidebarIsOpen}
						/>
					)}
					{sidebarIsOpen && (
						<>
							{role !== 'ambassador' && (
								<div
									className={`sidebar-action-lg ${selectedState.tasks ? 'active-tab' : ''}`}
									onClick={handleOpenTasks}
									role='link'
								>
									<img src={taskIcon} alt='task list' />
									<p className='link-text'> Task List </p>
								</div>
							)}
							{role !== 'ambassador' && (
								<div
									className={`sidebar-action-lg ${selectedState.settings ? 'active-tab' : ''}`}
									onClick={handleAddStaff}
									role='link'
								>
									<img src={settings} alt='settings' />
									<p className='link-text'> Settings </p>
								</div>
							)}
							{role !== 'ambassador' && (
								<div
									className={`sidebar-action-lg ${selectedState.organisations ? 'active-tab' : ''}`}
									onClick={handleOpenOrganization}
									role='link'
								>
									<img
										src={addStaff}
										alt='add organisation'
									/>
									<p className='link-text'>
										Organisation Database
									</p>
								</div>
							)}

							<div
								className='sidebar-action-lg'
								onClick={handleLogout}
								role='link'
							>
								<img src={logout} alt='logout' />
								<p className='logout-txt'>Logout</p>
							</div>
						</>
					)}

					{!sidebarIsOpen && (
						<>
							{role !== 'ambassador' && (
								<div
									className='sidebar-action-sm'
									onClick={handleOpenTasks}
									role='link'
								>
									<TooltipLg title='Open Task List'>
										<img
											src={
												selectedState.tasks
													? taskIconBlue
													: taskIcon
											}
											alt='task list'
										/>
									</TooltipLg>
								</div>
							)}
							{role !== 'ambassador' && (
								<div className='sidebar-action-sm'>
									<TooltipLg title='Settings'>
										<img
											src={
												selectedState.settings
													? settingsBlue
													: settings
											}
											alt='Settings'
											onClick={handleAddStaff}
										/>
									</TooltipLg>
								</div>
							)}
							{role !== 'ambassador' && (
								<div className='sidebar-action-sm' role='link'>
									<TooltipLg title='Organisation Database'>
										<img
											src={
												selectedState.organisations
													? addStaffBlue
													: addStaff
											}
											alt='organization'
											onClick={handleOpenOrganization}
										/>
									</TooltipLg>
								</div>
							)}

							<div className='sidebar-action-sm'>
								<TooltipLg title='Logout' role='link'>
									<img
										src={logout}
										alt='logout'
										onClick={handleLogout}
									/>
								</TooltipLg>
							</div>
						</>
					)}

					<div className='spacer'></div>
					<div className='toggle'>
						<button
							onClick={handleToggleSidebar}
							className='toggle-sidebar'
						>
							<img
								src={sidebarIsOpen ? arrowLeft : arrowRight}
								alt='open toggle'
							/>
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default Sidebar;
