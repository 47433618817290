import {
	GET_APPLICANT,
	GET_OVERALL_SUBMISSION,
	GET_CHECKLIST,
	UPDATE_APPLICANT,
	SET_SUB_PAGE,
	CLEAR_DATA,
	SET_LICENCES,
	SET_NAME_HISTORY,
	SET_ADDRESS_HISTORY,
	SET_WORK_HISTORY,
	SET_CRIMINAL_BANKRUPTCY,
	GET_AMBASSADOR_CHECK_STATUS,
	UPLOAD_FILES,
	GET_FILES,
	UPDATE_FILES,
	SET_SELECTED_FILES,
	RESET_SELECTED_FILES,
	REMOVE_SELECTED_FILE,
	SET_ID_CHECK_DATA,
	SAVE_LICENCES,
	UPDATE_APPLICANT_MULTIPLE,
	UPDATE_CLEARANCE,
	SHOW_ACTIVITY,
	PASS_ID_RESULT,
	CLEAR_AMBASSADOR,
	SET_STAGE_DATA,
	UPDATE_ASSIGNED_STAFF,
	BACK_FROM_ACTIVITY,
	UPDATE_OVERALL_SUBMISSION,
	SET_F2F_STATUS,
	SET_SHARE_CODE_DATA,
	SET_YOTI_DETAILS,
	SET_INDIVIDUAL_STAGE,
	SET_STAGE_RISKS,
	SET_REPORT_DOC_INFO,
} from './profile.types';
import uniqBy from 'lodash.uniqby';

const INITIAL_STATE = {
	applicant: [],
	overallSubmission: null,
	licences: [],
	nameHistory: [],
	workHistory: null,
	addressHistory: [],
	criminalBankruptcy: [],
	ambassadorCheckStatus: '',
	selectedFiles: [],
	files: [],
	idCheckData: null,
	showActivity: false,
	activityItem: null,
	passIdResult: null,
	stageData: [],
	backFromActivity: false,
	shareCodeData: null,
	yotiDetails: null,
	reportDocInfo: { type: '', expiryDate: '', url: '', code: '' },
};

const profileReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_APPLICANT:
			return {
				...state,
				applicant: action.applicant,
			};
		case GET_OVERALL_SUBMISSION:
			return {
				...state,
				overallSubmission: action.overallSubmission,
			};
		case UPDATE_OVERALL_SUBMISSION:
			return {
				...state,
				overallSubmission: {
					data: {
						...state.overallSubmission.data,
						status: action.status,
					},
				},
			};
		case GET_CHECKLIST:
			const uniqueChecklist = uniqBy(
				action.checklistData,
				(item) => item.id,
			);
			return {
				...state,
				checklistData: uniqueChecklist,
			};
		case UPDATE_APPLICANT:
			const key = Object.keys(action)[1];
			const value = Object.values(action)[1];
			return {
				...state,
				applicant: {
					...state.applicant,
					[key]: value,
				},
			};
		case SET_SUB_PAGE:
			return {
				...state,
				subPage: action.subPage,
			};
		case SET_LICENCES:
			return {
				...state,
				licences: action.licences,
			};
		case SAVE_LICENCES:
			return {
				...state,
				licences: [...state.licences, ...action.licences],
			};
		case SET_NAME_HISTORY:
			return {
				...state,
				nameHistory: action.nameHistory,
			};
		case SET_ADDRESS_HISTORY:
			return {
				...state,
				addressHistory: action.addressHistory,
			};
		case SET_WORK_HISTORY:
			return {
				...state,
				workHistory: action.workHistory,
			};
		case SET_CRIMINAL_BANKRUPTCY:
			return {
				...state,
				criminalBankruptcy: action.criminalBankruptcy,
			};
		case GET_AMBASSADOR_CHECK_STATUS:
			return {
				...state,
				ambassadorCheckStatus: action.ambassadorCheckStatus,
			};
		case SET_F2F_STATUS:
			return {
				...state,
				ambassadorCheckStatus: {
					...state.ambassadorCheckStatus,
					status: action.status,
				},
			};
		case SET_SHARE_CODE_DATA:
			return {
				...state,
				shareCodeData: action.payload,
			};
		case CLEAR_DATA:
			return INITIAL_STATE;

		case UPLOAD_FILES:
			return {
				...state,
				files: [...state.files, action.file],
			};
		case GET_FILES:
			return {
				...state,
				files: action.files,
			};
		case SET_SELECTED_FILES:
			let selected = [...state.selectedFiles, action.file];
			selected = uniqBy(selected, (item) => item.uuid);
			return {
				...state,
				selectedFiles: selected,
			};
		case REMOVE_SELECTED_FILE:
			const filteredArray = state.selectedFiles.filter(
				(file) => file.uuid !== action.file.uuid,
			);
			return {
				...state,
				selectedFiles: filteredArray,
			};
		case UPDATE_FILES:
			const updatedFiles = state.files.map((file) => {
				if (file.uuid === action.file.uuid) {
					file = action.file;
				}
				return file;
			});
			return {
				...state,
				files: [...updatedFiles],
			};
		case RESET_SELECTED_FILES:
			return {
				...state,
				selectedFiles: [],
			};

		case SET_ID_CHECK_DATA:
			return {
				...state,
				idCheckData: action.data,
			};

		case UPDATE_APPLICANT_MULTIPLE:
			for (let key in action.obj) {
				if (action.obj[key] !== '') {
					state.applicant[key] = action.obj[key];
				}
			}
			const updatedApplicant = state.applicant;
			return {
				...state,
				applicant: {
					...updatedApplicant,
				},
			};
		case UPDATE_CLEARANCE:
			return {
				...state,
				applicant: {
					...state.applicant,
					clearance: {
						...state.applicant.clearance,
						...action.obj,
					},
				},
			};
		case BACK_FROM_ACTIVITY:
			return {
				...state,
				backFromActivity: action.val,
			};
		case SHOW_ACTIVITY:
			return {
				...state,
				showActivity: action.data.showActivity,
				activityItem: action.data.activityItem,
			};
		case PASS_ID_RESULT:
			return {
				...state,
				passIdResult: true,
			};
		case SET_STAGE_DATA:
			return {
				...state,
				stageData: action.stageData,
			};
		case SET_INDIVIDUAL_STAGE:
			const updatedStageData = state.stageData.map((stage) => {
				if (stage.uuid === action.stageData.uuid) {
					for (const key of Object.keys(action.stageData)) {
						stage[key] = action.stageData[key];
					}
				}
				return stage;
			});
			return {
				...state,
				stageData: updatedStageData,
			};

		case SET_STAGE_RISKS:
			const upsertRisk = (stageRiskArr, actionRisk) => {
				for (const risk of stageRiskArr) {
					if (risk.uuid === actionRisk.uuid)
						return stageRiskArr.indexOf(risk);
				}
			};
			const stagesWithUpdatedRisk = state.stageData.map((stage) => {
				const { riskData, stageUuid } = action.risk;
				if (stage.uuid === stageUuid) {
					const riskIndex = upsertRisk(stage.risks, riskData);
					riskIndex >= 0
						? stage.risks.splice(riskIndex, 1, riskData)
						: stage.risks.push(riskData);
				}
				return stage;
			});
			return {
				...state,
				stageData: stagesWithUpdatedRisk,
			};
		case UPDATE_ASSIGNED_STAFF:
			const updatedAssignedApplicant = {
				...state.applicant,
				assignedStaff: action.assignedStaff,
			};
			return {
				...state,
				assignedStaff: updatedAssignedApplicant,
			};
		case CLEAR_AMBASSADOR:
			return {
				...state,
				overallSubmission: null,
				licences: [],
				nameHistory: [],
				workHistory: [],
				addressHistory: [],
				criminalBankruptcy: [],
				ambassadorCheckStatus: '',
				selectedFiles: [],
				files: [],
				idCheckData: null,
				showActivity: false,
				activityItem: null,
				passIdResult: null,
			};
		case SET_YOTI_DETAILS:
			return {
				...state,
				yotiDetails: action.details,
			};
		case SET_REPORT_DOC_INFO:
			return {
				...state,
				reportDocInfo: action.details,
			};
		default:
			return state;
	}
};

export default profileReducer;
