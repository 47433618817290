import './external-profile-sidebar.styles.scss';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../types/root-state';
import {
	formatDateToSlash,
	UserProfileInfoToolTip,
} from '../../../utils/externalUtilsFunction';
import { IApplicant } from '../../../types/interface';

import * as utils from '../../../utils/utilsFunctions';
import ExternalProfileSidebarSection from '../external-profile-sidebar-section/external-profile-sidebar-section.component';

import none from '../../../assets/icons/dropdown-none.svg';
import redError from '../../../assets/icons/red-error.svg';
import limited from '../../../assets/icons/dropdown-limited.svg';
import fully from '../../../assets/icons/dropdown-fully.svg';
import yellowInfoIcon from '../../../assets/icons/yellow-info.svg';
import blueInfoIcon from '../../../assets/icons/blue-info.svg';
import lightGreyChevronUp from '../../../assets/icons/light-grey-chevron-up.svg';
import blueChevronUp from '../../../assets/icons/blue-chevron-up.svg';
import useGetRtwReport from '../../../utils/useGetRtwReport.component';
import ExternalProfileReviewModal from '../../external-dashboard/external-profile-review-modal/external-profile-review-modal.component';
import { ReviewModalType } from '../../../types/interfaces/rtw.interface';

interface ClearanceDetailsProps {
	applicant: IApplicant;
	onCertificateClick: () => void;
	onReportClick: () => void;
}

type Props = {
	isModalVisible: boolean;
	reviewModalType: ReviewModalType;
	closeModal: () => void;
};

const ExternalProfileSidebar: FC<Props> = ({ closeModal, reviewModalType, isModalVisible }) => {
	const navigate = useNavigate();

	const { applicant, overallSubmission } = useSelector(
		(state: RootState) => state.profile,
	);

	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	useGetRtwReport();
	const [isClearanceTooltip, setIsClearanceTooltip] = useState(false);
	const [isReportTooltip, setIsReportTooltip] = useState(false);
	const [isLimitedTooltip, setIsLimitedTooltip] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const collapsibleContentStyle = { display: isOpen ? 'block' : 'none' };

	const submissionData = overallSubmission?.data || {};
	const hasSignedDate = !!submissionData.signedDate;
	const signatureUrl = submissionData.signatureUrl;

	const workerDeclarationText = hasSignedDate ? (
		<a
			href={signatureUrl}
			className='external-profile-sidebar-worker-declaration-link'
			target='_blank'
			rel='noopener noreferrer'
		>
			View Document
		</a>
	) : (
		'No Document'
	);

	const workerDeclarationContent =
		applicant.flow === 'non-works' ? workerDeclarationText : 'No Document';

	const handleClearanceTooltip = (value: boolean) => {
		setIsClearanceTooltip(value);
	};

	const handleReportTooltip = (value: boolean) => {
		setIsReportTooltip(value);
	};

	const handleLimitedTooltip = (value: boolean) => {
		setIsLimitedTooltip(value);
	};

	const getClearanceStatusIcon = () => {
		if (
			applicant.overallSubmissionStatus === 'cancelled' ||
			applicant.paused
		) {
			return redError;
		}

		switch (clearance?.clearanceStatus) {
			case 'partially_compliant':
				return limited;
			case 'fully_compliant':
				return fully;
			default:
				return none;
		}
	};

	const getClearanceStatusText = () => {
		if (
			applicant.overallSubmissionStatus === 'cancelled' ||
			applicant.paused
		) {
			return 'Cancelled';
		}

		switch (clearance?.clearanceStatus) {
			case 'partially_compliant':
				return 'Limited Screening';
			case 'fully_compliant':
				return 'Full';
			default:
				return 'Not Complete';
		}
	};

	const ClearanceDetails = ({
		applicant,
		onCertificateClick,
		onReportClick,
	}: ClearanceDetailsProps) => {
		return (
			<>
				<ExternalProfileSidebarSection
					header={
						applicant.clearance?.clearanceStatus ===
						'fully_compliant'
							? 'Date of Fully Cleared'
							: 'Date of Limited Screening'
					}
					text={formatDateToSlash(
						applicant.clearance?.clearanceStatus ===
							'fully_compliant'
							? applicant.clearance?.fullyClearedDate || ''
							: applicant.clearance?.provisionallyClearedDate ||
									'',
					)}
				/>
				{applicant.clearance?.clearanceStatus ===
					'partially_compliant' && (
					<>
						<div className='days-left-container'>
							<div className='days-left'>
								<p>
									{utils.calculateDaysLeftToClear(applicant)}
								</p>
							</div>
							<img
								onMouseEnter={() => handleLimitedTooltip(true)}
								onMouseLeave={() => handleLimitedTooltip(false)}
								src={yellowInfoIcon}
								alt='yellow-info-icon'
								className='yellow-info-icon'
							/>
						</div>
						<UserProfileInfoToolTip
							title={
								'Once a candidate has received limited clearance, we have 12 weeks to complete their screening before they will need to update and restart their process.'
							}
							placement='bottom-start'
							open={isLimitedTooltip}
						>
							<span />
						</UserProfileInfoToolTip>
					</>
				)}
				<div
					className={`external-profile-sidebar-view-certificate${
						applicant.clearance?.clearanceStatus ===
						'fully_compliant'
							? '-full'
							: applicant.clearance?.clearanceStatus ===
								  'partially_compliant'
								? '-limited'
								: ''
					}`}
					onClick={onCertificateClick}
				>
					<p className='external-profile-sidebar-view-links'>
						View Certificate
					</p>
					<img
						onMouseEnter={() => handleClearanceTooltip(true)}
						onMouseLeave={() => handleClearanceTooltip(false)}
						src={blueInfoIcon}
						alt='blue-info-icon'
						className='blue-info-icon'
					/>
				</div>
				<UserProfileInfoToolTip
					title={
						"The certificate is a short document that advises the candidate's clearance status without disclosing unnecessary information."
					}
					placement='bottom-start'
					open={isClearanceTooltip}
					PopperProps={{
						modifiers: [
							{
								name: 'offset',
								options: {
									offset: [0, -15],
								},
							},
						],
					}}
				>
					<span />
				</UserProfileInfoToolTip>
				{applicant.flow === 'non-works' &&
					clearance &&
					clearance.clearanceStatus !== 'not_compliant' && (
						<>
							<div
								className='external-profile-sidebar-view-report'
								onClick={onReportClick}
							>
								<p className='external-profile-sidebar-view-links'>
									View Report
								</p>
								<img
									onMouseEnter={() =>
										handleReportTooltip(true)
									}
									onMouseLeave={() =>
										handleReportTooltip(false)
									}
									src={blueInfoIcon}
									alt='blue-info-icon'
									className='blue-info-icon'
								/>
							</div>
							<UserProfileInfoToolTip
								title={
									'This is the full clearance report, with all appropriate documentary evidence and outcomes included.'
								}
								placement='bottom-start'
								open={isReportTooltip}
								PopperProps={{
									modifiers: [
										{
											name: 'offset',
											options: {
												offset: [0, -15],
											},
										},
									],
								}}
							>
								<span />
							</UserProfileInfoToolTip>
						</>
					)}
			</>
		);
	};

	const handleViewCertificate = () => {
		return navigate('/check/certificate');
	};

	const handleViewReport = () => {
		return navigate('/check/report');
	};

	return (
		<>
			{isModalVisible ? 
				<ExternalProfileReviewModal
					closeModal={closeModal}
					reviewModalType={reviewModalType}
				/>
				: <div className='external-profile-sidebar-container'>
					<section className='external-profile-sidebar'>
						<ExternalProfileSidebarSection
							header='Date Created'
							text={formatDateToSlash(applicant.createdAt)}
							iconShown={true}
							tooltipMessage='Date the candidate was added to Deploi. If you are buying a passport, this will be the date the passport was first created.'
						/>
						<ExternalProfileSidebarSection
							header='Submission Date'
							text={
								overallSubmission?.data?.signedDate
									? formatDateToSlash(
											overallSubmission.data.signedDate.toString(),
										)
									: 'Not Available'
							}
							iconShown={true}
							tooltipMessage='Date the candidate submitted their application.'
						/>
						<ExternalProfileSidebarSection
							header='Submission Status'
							text={
								overallSubmission?.data?.status
									? utils.capitalizeFirstLetterOfEach(
											overallSubmission.data.status,
										)
									: 'Not Available'
							}
							iconShown={true}
							tooltipMessage="This shows the status of the candidate's application form."
						/>
						<ExternalProfileSidebarSection
							header='Worker Declaration'
							text={workerDeclarationContent}
							iconShown={true}
							tooltipMessage='Should you require it, this is a PDF of the most recent version of the declaration that this candidate has signed.'
						/>
						<ExternalProfileSidebarSection
							header='Clearance Status'
							text={getClearanceStatusText()}
							icon={getClearanceStatusIcon()}
							iconShown={true}
							tooltipMessage='The level of clearance that the candidate has.'
						/>
						{(clearance?.provisionallyClearedDate ||
							clearance?.fullyClearedDate) && (
							<ClearanceDetails
								applicant={applicant}
								onCertificateClick={handleViewCertificate}
								onReportClick={handleViewReport}
							/>
						)}
						<ExternalProfileSidebarSection
							header='NI Number'
							text={
								applicant.nationalInsuranceNumber
									? applicant.nationalInsuranceNumber
									: 'N/A'
							}
							collapsibleStyle={collapsibleContentStyle}
						/>
						<ExternalProfileSidebarSection
							header='Phone Number'
							text={
								applicant.contactNumber
									? applicant.contactNumber
									: 'N/A'
							}
							collapsibleStyle={collapsibleContentStyle}
						/>
						<ExternalProfileSidebarSection
							header='Date of Birth'
							text={
								applicant.DOB instanceof Date
									? applicant.DOB.toDateString() // Format Date case
									: applicant.DOB
										? applicant.DOB
										: 'N/A'
							}
							collapsibleStyle={collapsibleContentStyle}
						/>
						<ExternalProfileSidebarSection
							header='Email'
							text={
								<div className='external-profile-sidebar-email-container'>
									<a
										href={`mailto:${applicant.email}`}
										target='_blank'
										rel='noopener noreferrer'
										className='external-profile-sidebar-email'
									>
										{applicant.email}
									</a>
								</div>
							}
							collapsibleStyle={collapsibleContentStyle}
						/>
						<br />
						<div
							className='external-profile-sidebar-divider-line'
							style={{
								marginTop: isOpen ? '' : -60,
							}}
						></div>
						<br />
						<div
							className='external-profile-sidebar-collapsible-container'
							onClick={() => setIsOpen(!isOpen)}
						>
							<p
								className='external-profile-sidebar-collapsible-text'
								style={{ color: isOpen ? '#9AA5B1' : '#485BEB' }}
							>
								{isOpen ? 'SHOW SUMMARY' : 'VIEW ALL INFO'}
							</p>
							<img
								className='chevron'
								src={isOpen ? lightGreyChevronUp : blueChevronUp}
								alt='arrow'
								style={{ transform: isOpen ? '' : 'rotate(180deg)' }}
							/>
						</div>
					</section>
				</div>
			}
		</>
	);
};

export default ExternalProfileSidebar;
