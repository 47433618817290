import React, { useState, FC } from 'react';
import './external-profile-review-modal.scss';
import { getReviewModalDetails } from '../../../utils/externalUtilsFunction';
import manualBookIcon from '../../../assets/icons/manual-book.svg';
import crossIcon from '../../../assets/icons/cross.svg';
import checkboxBlue from '../../../assets/icons/checkbox-blue.svg';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import { setAlert } from '../../../redux/General/general.actions';
import { ReviewModalType } from '../../../types/interfaces/rtw.interface';
import { useDispatch } from 'react-redux';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';

type Props = {
	closeModal: () => void;
	reviewModalType: ReviewModalType;
};

const ExternalProfileReviewModal: FC<Props> = ({
	closeModal,
	reviewModalType,
}) => {
	const dispatch = useDispatch();
	const reviewModalDetails = getReviewModalDetails(reviewModalType);

	const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
	const [date, setDate] = useState('');
	const [alertConfirmTitle, setAlertConfirmTitle] = useState<string>('');
	const [alertConfirmMessage, setAlertConfirmMessage] = useState<string>('');
	const [alertConfirmButtonText, setAlertConfirmButtonText] = useState<string>('');
	const [showAlertConfirm, setShowAlertConfirm] = useState(false);

	function isValidDate(dateString: string) {
		const parts = dateString.split('/');

		if (parts.length !== 3) {
			return null;
		}

		const day = parseInt(parts[0], 10);
		const month = parseInt(parts[1], 10) - 1;
		const year = parseInt(parts[2], 10);

		const today = new Date();
		const inputDate = new Date(year, month, day);

		if (isNaN(inputDate.getTime())) {
			return false;
		}

		return inputDate > today;
	}

	const handleCheckboxSelection = () => {
		if (reviewModalType === 'share-code') {
			if(isValidDate(date)) {
				setIsCheckboxSelected(
					(isCheckboxSelected) => !isCheckboxSelected,
				);
				return;
			} else {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Please ensure you have added a valid Expiry Date',
						isVisible: true,
					}),
				);
				return;
			}
		}
		setIsCheckboxSelected((isCheckboxSelected) => !isCheckboxSelected);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let input = e.target.value.replace(/\D/g, '');
		if (input.length <= 4) {
			input = input.slice(0, 2) + '/' +
			input.slice(2);
		} else {
			input = input.slice(0, 2) + '/' +
			input.slice(2, 4) + '/' +
			input.slice(4, 8);
		}
		setDate(input);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Backspace' || e.key === 'Delete') {
			return;
		}
		if (date.length >= 10 && /\d/.test(e.key)) {
			e.preventDefault();
		}
	};

	const handleReviewActionButtonClick = () => {
		if (
			reviewModalDetails?.buttonActionTitle &&
			reviewModalDetails?.buttonActionMessage &&
			reviewModalDetails?.buttonActionOpt
		) {
			setAlertConfirmTitle(reviewModalDetails.buttonActionTitle);
			setAlertConfirmMessage(reviewModalDetails.buttonActionMessage);
			setAlertConfirmButtonText(reviewModalDetails.buttonActionOpt);
			setShowAlertConfirm(true);
		}
	};

	const handleResetIdentitryCheck = () => {
		setAlertConfirmTitle('Are you sure?');
		setAlertConfirmMessage(
			'Do you want to reset this candidates identity check?',
		);
		setAlertConfirmButtonText('Yes');
		setShowAlertConfirm(true);
	};

	const handleGetValFromAlert = (val: boolean) => {
		setShowAlertConfirm(false);
		// TODO: add api requests once Manager/Admin access added to endpoints
	};

	const emitSaveConfirmation = () => {
		// TODO: add api request to endpoint once created
		return;
	};

	return (
		<>
			{reviewModalDetails && 
				<div className='review-modal-container'>
					<div className='review-modal-top'>
						<img
							onClick={() => closeModal()}
							className='review-modal-close'
							src={crossIcon}
						/>
						<p className='review-modal-header'>
							{reviewModalDetails.title}
						</p>
						<div className='review-modal-divider'></div>
						<div className='review-modal-confirmation-container'>
							{reviewModalType === 'share-code' && 
								<div className='review-modal-expiry'>
									<label
										htmlFor='date-input'
										className='semi'
									>
											RTW Expiry Date:
									</label>
									<input
										type='text'
										id='date-input'
										placeholder='DD/MM/YYYY'
										maxLength={10}
										value={date}
										className='review-modal-expiry-date'
										onChange={handleInputChange}
										onKeyDown={handleKeyDown}
									/>
								</div>
							}
							<div className='review-modal-confirmation'>
								<div className='review-modal-confirmation-checkbox'>
									<img
										onClick={() =>
											handleCheckboxSelection()
										}
										src={
											isCheckboxSelected
												? checkboxBlue
												: checkboxWhite
										}
										alt='checkbox'
									/>
								</div>
								<div className='review-modal-confirmation-message'>
									<p className='orka-p-medium'>
										{reviewModalDetails.bodyText}
									</p>
									<button
										disabled={!isCheckboxSelected}
										className='review-modal-confirmation-button orka-p-small'
										onClick={emitSaveConfirmation}
									>
										Save confirmation
									</button>
								</div>
							</div>
						</div>
						<div
							className='review-modal-guidance orka-p-small'
							onClick={() =>
								window.open(`${process.env.REACT_APP_MODAL_GUIDE_LINK}`,
								 '_blank')}
							>
							<img
								className='review-modal-guidance-img'
								src={manualBookIcon}
								alt='guide manual icon'
							/>
							<p className='orka-p-small review-modal-guidance-text'>
								<span className='semi'>Guide: </span>
								How to perform a right to work check
							</p>
						</div>
					</div>
					<div className='review-modal-bottom'>
						{reviewModalDetails.button1 !== '' && (
							<button
								className='review-action-button orka-p-small'
								onClick={handleReviewActionButtonClick}
							>
								{reviewModalDetails.button1}
							</button>
						)}
						{showAlertConfirm && (
							<AlertConfirm
								title={alertConfirmTitle}
								message={alertConfirmMessage}
								getVal={handleGetValFromAlert}
								confirm={alertConfirmButtonText}
								reject='Go back'
								reviewModalType={reviewModalType}
							/>
						)}
						<button
							className='review-action-button orka-p-small'
							onClick={handleResetIdentitryCheck}
						>
							Reset Identity Check
						</button>
						<p className='orka-p-small review-action-text'>
							Resetting either check will mean the candidate needs
							to start that check process again. Your plan
							includes up to two resets per check.
						</p>
					</div>
				</div>
			}
		</>
	);
};

export default ExternalProfileReviewModal;
