import './external-user-profile-tabs.styles.scss';

import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';
import { setAlert } from '../../../redux/General/general.actions';
import {
	getFiles,
	setShareCodeData,
	setYotiDetails,
} from '../../../redux/Profile/profile.actions';
import { IYotiData } from '../../../types/interfaces/rtw.interface';
import { YotiDocumentDetails } from '../../../types/interface';

import * as apiId from '../../../api/api.identity';
import * as docsApi from '../../../api/api.docs';
import * as identityApi from '../../../api/api.identity';
import ExternalUserProfileIdentity from '../external-user-profile-sections/external-user-profile-identity.component';
import ExternalUserProfileActivity from '../external-user-profile-sections/external-user-profile-activity.component';
import ExternalUserProfileQualifications from '../external-user-profile-sections/external-user-profile-qualifications.component';
import ExternalUserProfileAddress from '../external-user-profile-sections/external-user-profile-address.component';
import ExternalUserProfileChecks from '../external-user-profile-sections/external-user-profile-checks.component';
import ExternalUserProfileFiles from '../external-user-profile-sections/external-user-profile-files.component';
import * as utils from '../../../utils/utilsFunctions';
import { ReviewModalType } from '../../../types/interfaces/rtw.interface';

type Props = {
	toggleModal: (value: boolean) => void;
	handleReviewModalType: (value: ReviewModalType) => void;
};

const ExternalUserProfileTabs: FC<Props>= ({ toggleModal, handleReviewModalType }) => {
	const dispatch = useDispatch();

	const loggedInUser = useSelector((state: RootState) => state.auth.user);

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const tabs = [
		'Identity',
		'Activity',
		'Qualifications',
		'Address',
		'Checks',
		'Files',
	];

	const [activeTab, setActiveTab] = useState('Identity');

	const refs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

	tabs.forEach((tab) => {
		refs.current[tab] = useRef<HTMLDivElement>(null);
	});

	const handleTabClick = (tab: string) => {
		setActiveTab(tab);
		refs.current[tab].current?.scrollIntoView({ behavior: 'smooth' });
	};

	const files = useSelector((state: RootState) => state.profile.files);

	const [rtwManualDocArray, setRtwManualDocArray] = useState<IFileItem[]>([]);
	const [addressFileArray, setAddressFileArray] = useState<IFileItem[]>([]);
	const [additionalFileArray, setAdditionalFileArray] = useState<IFileItem[]>(
		[],
	);
	const [yotiFaceToFaceArray, setYotiFaceToFaceArray] = useState<YotiDocs[]>(
		[],
	);
	const [yotiRtwArray, setYotiRtwArray] = useState<YotiDocs[]>([]);
	const [faceToFaceFileArray, setFaceToFaceFileArray] = useState<IFileItem[]>(
		[],
	);
	const [niFileArray, setNiFileArray] = useState<IFileItem[]>([]);
	const [yotiDocumentDetails, setYotiDocumentDetails] =
		useState<YotiDocumentDetails>();

	useEffect(() => {
		const container = document.querySelector(
			'.external-user-profile-tab-container',
		);

		const handleScroll = () => {
			if (container) {
				const containerTop = container.scrollTop;

				tabs.forEach((tab) => {
					const current = refs.current[tab].current;
					if (current) {
						const tabTop =
							current.getBoundingClientRect().top -
							container.getBoundingClientRect().top +
							container.scrollTop;
						if (
							containerTop > tabTop - 100 &&
							containerTop < tabTop + 100
						) {
							setActiveTab(tab);
						}
					}
				});
			}
		};

		handleScroll();
		if (container) {
			container.addEventListener('scroll', handleScroll);
			return () => container.removeEventListener('scroll', handleScroll);
		}
	}, [refs, activeTab, setActiveTab]);

	useEffect(() => {
		if (applicant.workerUuid) {
			getDocuments();
			getYotiDetails();
			getYotiDocuments();
			getShareCodeDetails();
		}
	}, [applicant.workerUuid, dispatch, applicant.uuid]);

	useEffect(() => {
		const processFiles = () => {
			const rtwManualDocFiles: IFileItem[] = [];
			const addressFiles: IFileItem[] = [];
			const additionalFiles: IFileItem[] = [];
			const faceToFaceFiles: IFileItem[] = [];
			const niFiles: IFileItem[] = [];
			if (files && files.length > 0) {
				let allFiles = files.map((file: any) => {
					if (typeof file.tags === 'string') {
						file.tags = file.tags
							.replace(/(?!,)\W/g, '')
							.split(',');
					}
					file.status = file.tags[0];
					file.docType = file.tags[1];
					return file;
				});

				allFiles = allFiles.filter((file) => file.deletedAt === null);
				allFiles.forEach((f) => {
					if (f.docType === 'rightToWorkManual') {
						rtwManualDocFiles.push(f);
					} else if (
						f.docType === 'proofOfCurrentAddress' ||
						f.docType === 'proofOfAddress'
					) {
						addressFiles.push(f);
					} else if (f.docType === 'additionalDocs') {
						additionalFiles.push(f);
					} else if (
						f.docType === 'proofOfNINumber' ||
						f.docType === 'proofOfNI'
					) {
						niFiles.push(f);
					} else if (f.docType === 'faceToFace') {
						faceToFaceFiles.push(f);
					}
				});
				setRtwManualDocArray(rtwManualDocFiles);
				setAddressFileArray(addressFiles);
				setAdditionalFileArray(additionalFiles);
				setNiFileArray(niFiles);
				setFaceToFaceFileArray(faceToFaceFiles);
			}
		};
		processFiles();
	}, [JSON.stringify(files)]);

	const getYotiDetails = () => {
		apiId
			.yotiDetails(applicant.workerUuid)
			.then(({ data }) => {
				const { candidate } = data.data || '';
				if (candidate) {
					const yotiData: IYotiData = {
						override: candidate.override,
						flow: candidate.yotiSession.flow,
						sessionStatus: candidate.yotiSession.sessionStatus,
						sessionId: candidate.yotiSession.sessionId,
						sessionUuId: candidate.yotiSession.uuid,
					};
					dispatch(setYotiDetails(yotiData));
					if (
						candidate.yotiSession &&
						candidate.yotiSession.details
					) {
						setYotiDocumentDetails(candidate.yotiSession.details);
					}
				}
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to get RTW details',
						isVisible: false,
					}),
				);
			});
	};

	const getYotiDocuments = () => {
		apiId
			.yotiDocuments(applicant.workerUuid)
			.then(({ data }) => {
				const { yotiRtwArray, yotiFaceToFaceArray } =
					utils.mapYotiDocsImages(data);
				setYotiRtwArray(yotiRtwArray);
				setYotiFaceToFaceArray(yotiFaceToFaceArray);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error getting yoti documents',
						isVisible: true,
					}),
				);
			});
	};

	const getDocuments = () => {
		docsApi
			.getDocuments(applicant.workerUuid)
			.then(({ data }) => {
				const files = data.data.filter(
					(file: IFileItem) => file.deletedAt === null,
				);
				dispatch(getFiles(files));
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error getting documents',
						isVisible: false,
					}),
				);
			});
	};

	const getShareCodeDetails = () => {
		identityApi
			.getShareCode(applicant.workerUuid)
			.then((res) => {
				const data = res.status === 404 ? null : res.data.data;
				dispatch(setShareCodeData(data));
			})
			.catch((err) => {
				throw err;
			});
	};
	
	return (
		<div className='external-user-profile-tab-container'>
			<div className='external-user-profile-tabs-header'>
				{tabs.map((tab) => (
					<div
						key={tab}
						className={`external-user-profile-tabs-header-tab ${activeTab === tab ? 'active' : ''
							}`}
						onClick={() => handleTabClick(tab)}
					>
						{tab.toUpperCase()}
					</div>
				))}
			</div>
			<br />
			<div ref={refs.current.Identity}>
				<ExternalUserProfileIdentity
					rtwManualDocArray={rtwManualDocArray}
					yotiFaceToFaceArray={yotiFaceToFaceArray}
					yotiRtwArray={yotiRtwArray}
					faceToFaceArray={faceToFaceFileArray}
					yotiDocumentDetails={yotiDocumentDetails}
					toggleModal={toggleModal}
					handleReviewModalType={handleReviewModalType}
					niFileArray={niFileArray}
				/>
			</div>
			<div ref={refs.current.Activity}>
				<ExternalUserProfileActivity loggedInUser={loggedInUser} />
			</div>
			<div ref={refs.current.Qualifications}>
				<ExternalUserProfileQualifications
					loggedInUser={loggedInUser}
				/>
			</div>
			<div ref={refs.current.Address}>
				<ExternalUserProfileAddress
					addressFileArray={addressFileArray}
				/>
			</div>
			<div ref={refs.current.Checks}>
				<ExternalUserProfileChecks loggedInUser={loggedInUser} />
			</div>
			<div ref={refs.current.Files}>
				<ExternalUserProfileFiles
					additionalFileArray={additionalFileArray}
				/>
			</div>
		</div>
	);
};

export default ExternalUserProfileTabs;
