import './external-user-profile-sections.styles.scss';

import React, { FC, useState , useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { formatDateToText } from '../../../utils/externalUtilsFunction';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';
import { YotiDocumentDetails } from '../../../types/interface';
import { ReviewModalType } from '../../../types/interfaces/rtw.interface';
import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';
import LivenessCheck from '../../profile/liveness-check/liveness-check.component';
import RtwImageDoc from '../../profile/rtw-image-doc/rtw-image-doc';
import ProfileDocumentDetails from '../../profile/profile-document-details/profile-document-details.component';
import { setAlert } from '../../../redux/General/general.actions';

import cheveronDown from '../../../assets/icons/chevron-down.svg';
import greenTick from '../../../assets/icons/green-tick.svg';
import downloadFileIcon from '../../../assets/icons/external-portal/user-profile/download-file.svg';
import copyIcon from '../../../assets/icons/copy-grey.svg';
import YotiBadge from '../../yoti/yoti-badge/yoti-badge.comonent';
import GuidanceToggle from '../../general/guidance-toggle/guidance-toggle.component';
import ProfileDocumentBucket from '../../profile/profile-document-bucket/profile-document-bucket.component';
import ProfileFileBucket from '../../profile/profile-file-bucket/profile-file-bucket.component';

type Props = {
	rtwManualDocArray: IFileItem[];
	yotiFaceToFaceArray: YotiDocs[];
	yotiRtwArray: YotiDocs[];
	faceToFaceArray: IFileItem[];
	yotiDocumentDetails?: YotiDocumentDetails;
	toggleModal: (value: boolean) => void;
	handleReviewModalType: (value: ReviewModalType) => void;
	niFileArray: IFileItem[];
};

const ExternalUserProfileIdentity: FC<Props> = ({
	rtwManualDocArray,
	yotiFaceToFaceArray,
	yotiRtwArray,
	faceToFaceArray,
	yotiDocumentDetails,
	toggleModal,
	handleReviewModalType,
	niFileArray
}) => {
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const [rightToWorkType, setRightToWorkType] = useState('none');
	// TODO: needs to be updated when checkType is available in root state
	const [checkType, setCheckType] = useState('rtw');
	const collapsibleSectionStyle = { display: isOpen ? 'block' : 'none' };

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const role = useSelector(
		(state: RootState) => state.auth.role,
	);

	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);

	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);

	const { shareCodeData } = useSelector(
		(state: RootState) => state.profile,
	);

	const { ambassadorCheckStatus } = useSelector(
		(state: RootState) => state.profile,
	);

	const visibleFiles = faceToFaceArray.filter(
		(file: IFileItem) => !file.deletedAt && file.status !== 'rejected',
	);

	const copyToClipboard = (copyText: string) => {
		navigator.clipboard
			.writeText(copyText)
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully copied share code',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to copy share code',
						isVisible: true,
					}),
				);
			});
	};

	const formatConfidenceLevel = (levelOfConfidence?: string) => {
		if (!levelOfConfidence) {
			return 'Medium';
		}

		if (levelOfConfidence === 'very_high') {
			return 'Very high';
		}
		return (
			levelOfConfidence.charAt(0).toUpperCase() +
			levelOfConfidence.slice(1)
		);
	};
	
	const handleRightToWorkType = () => {
		let rightToWorkType: ReviewModalType = 'none';
		if (yotiRtwArray?.length > 0) {
			rightToWorkType = 'digital-passport';
		} else if (rtwManualDocArray?.length > 0) {
			rightToWorkType = 'document';
		} else if (shareCodeData && Object.keys(shareCodeData).length > 0) {
			rightToWorkType = 'share-code';
		}
		if (rightToWorkType) {
			setRightToWorkType(rightToWorkType);
		}
		if (
			(role === 'manager' || role === 'admin') &&
			(checkType === 'rtw' || checkType === 'rtwbs7858')
		) {
			handleReviewModalType(rightToWorkType);
		}
};

	useEffect(() => {
		handleRightToWorkType()
	  }, [faceToFaceArray, rtwManualDocArray, yotiRtwArray, shareCodeData]);

	const toggleDropdown = () => {
		if (rightToWorkType !== 'none' &&
			checkType !== 'bs7858'
		) {
			toggleModal(isOpen);
		}
		setIsOpen(!isOpen);
	};

	const mostRecentImg = () => {
		return rtwManualDocArray.slice(-1)[0];
	};

	const renderFaceToFaceFile = () => {
		return (
			<>
				{faceToFaceArray && faceToFaceArray.length > 0 ? (
					<div className='external-user-profile-sub-section-body'>
						<div className='external-user-profile-sub-section-file-container'>
							<p className='external-user-profile-sub-section-file-title'>
								Files
							</p>
							{visibleFiles.map(
								(file: IFileItem, index: number) => (
									<div
										key={index}
										className='external-user-profile-sub-section-file-content'
									>
										<a
											href={file.readUrl}
											target='_blank'
											rel='noreferrer'
											className='external-user-profile-sub-section-file-link'
										>
											<img
												className='external-user-profile-sub-section-file-icon'
												src={downloadFileIcon}
												alt='file-icon'
											/>
											<span className='external-user-profile-sub-section-file-number'>
												File{' '}
												{visibleFiles.indexOf(file) + 1}
											</span>
										</a>
									</div>
								),
							)}
						</div>
					</div>
				) : (
					<div className='external-user-profile-sub-section-file-container'>
						<p className='external-user-profile-sub-section-file-title'>
							Files
						</p>
						<p className='external-user-profile-sub-section-file-text'>
							No Files Available
						</p>
					</div>
				)}
			</>
		);
	};

	const renderRtwSection = () => {
		return (
			<>
				<section className='sub-section-container'>
					<div className='sub-section-title-container remove-pointer'>
						<p className='sub-section-title'>RIGHT TO WORK</p>
						<div>
							{yotiDetails && yotiDetails.sessionStatus && (
								<YotiBadge yotiDetails={yotiDetails} />
							)}
						</div>
					</div>
					<div className='sub-section-identity-container'>
						{rightToWorkType === 'digital-passport' && (
							<>
								<div className='sub-section-identity-document'>
									<RtwImageDoc
										imgSrc={yotiRtwArray[0].readUrl}
									/>
								</div>
								<div className='sub-section-identity-confidence-level'>
									<img
										className='sub-section-identity-confidence-level-icon'
										src={greenTick}
										alt='Check'
									/>
									{yotiDocumentDetails &&
										yotiDocumentDetails.levelOfConfidence &&
										formatConfidenceLevel(
											yotiDocumentDetails.levelOfConfidence,
										) + ' level of confidence'}
								</div>
								<GuidanceToggle guidanceType='rtw-yoti' />
							</>
						)}
						{rightToWorkType === 'document' && (
							<>
								<div className='sub-section-identity-document'>
									<RtwImageDoc
										imgSrc={mostRecentImg().readUrl}
									/>
								</div>
								<GuidanceToggle guidanceType='rtw-all-other-docs' />
							</>
						)}
						{rightToWorkType === 'share-code' && renderShareCodeSection()}
					</div>
				</section>
				{yotiDocumentDetails && renderRTWYotiDetails()}
			</>
		);
	};

	const renderRTWYotiDetails = () => {
		return (
			<>
				{yotiDocumentDetails && (
					<ProfileDocumentDetails
						yotiDocumentDetails={yotiDocumentDetails}
						isExternal={true}
					/>
				)}
			</>
		);
	};

	const livenessCheck = () => {
		if (!yotiDetails || yotiFaceToFaceArray.length === 0) {
			return;
		}
		return (
			<LivenessCheck
				fileArray={yotiFaceToFaceArray}
				showContainerClass={false}
			/>
		);
	};

	const renderLivenessSection = () => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-title-container remove-pointer'>
					<p className='sub-section-title'>LIVENESS CHECK</p>
				</div>
				<div className='sub-section-identity-container'>
					{livenessCheck()}
					<GuidanceToggle guidanceType='liveness-check' />
				</div>
			</section>
		);
	};

	const renderIdentitySection = () => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-title-container remove-pointer'>
					<p className='sub-section-title'>IDENTITY CHECK</p>
				</div>
				<div className='sub-section-identity-container'>
					<div className='external-user-profile-sub-section-identity-subtitle-container'>
						<p className='external-user-profile-sub-section-identity-subtitle'>
							Ambassador Name:
						</p>
						<div className='external-user-profile-sub-section-identity-body'>
							{applicant.f2fCompletedBy
								? applicant.f2fCompletedBy
								: 'N/A'}
						</div>
					</div>
					<div className='external-user-profile-sub-section-identity-subtitle-container'>
						<p className='external-user-profile-sub-section-identity-subtitle'>
							Date Checked:
						</p>
						<div className='external-user-profile-sub-section-identity-body'>
							{applicant.f2fCompletedDate
								? utils.formatDateForUser(
										applicant.f2fCompletedDate,
									)
								: 'N/A'}
						</div>
					</div>
					{renderFaceToFaceFile()}
					<GuidanceToggle guidanceType='' />
				</div>
			</section>
		);
	};

	const renderShareCodeSection = () => {
		return (
			<>
				<div className='sub-section-share-code'>
					<p className='share-code-title'>Provided Share Code</p>
					<p>
						{shareCodeData.shareCode}
						<img
							src={copyIcon}
							alt='copy text'
							className='sub-section-share-code-copy'
							onClick={() =>
								copyToClipboard(shareCodeData.shareCode)
							}
						></img>
					</p>
				</div>
				<br></br>
				<div className='sub-section-share-code'>
					<p className='share-code-title'>Date of Birth</p>
					{shareCodeData.dob ? (
						<p>
							{utils.formatDateForUser(
								shareCodeData.dob.toString(),
							)}
							<img
								src={copyIcon}
								alt='copy text'
								className='sub-section-share-code-copy'
								onClick={() =>
									copyToClipboard(
										utils.formatDateForUser(
											shareCodeData.dob.toString(),
										),
									)
								}
							></img>
						</p>
					) : (
						<p>N/A</p>
					)}
				</div>
				<br></br>
				<a
					className='sub-section-share-code-link'
					target='_blank'
					rel='noopener noreferrer'
					href={process.env.REACT_APP_GOV_SHARECODE_LINK}
				>
					Go to Gov share code checker
				</a>
				<ProfileDocumentBucket
						tag='rightToWork'
						fileArray={niFileArray}
						isWork={true}
				/>
				<GuidanceToggle guidanceType='rtw-share-code' />
			</>
		);
	};

	const renderNISection = () => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-title-container remove-pointer'>
					<p className='sub-section-title'>
						NATIONAL INSURANCE NUMBER
					</p>
				</div>
				<div className='sub-section-identity-container'>
					<p className='sub-section-subtitle'>
						{applicant.nationalInsuranceNumber.toUpperCase()}
					</p>
					<ProfileDocumentBucket
						tag='proofOfNINumber'
						fileArray={niFileArray}
						isWork={true}
					/>
					<br></br>
					<GuidanceToggle guidanceType='ni-number' />
				</div>
			</section>
		);
	};

	const getSectionStatus = () => {
		if (stageData[0]?.stageComplete && stageData[7]?.stageComplete) {
			return 'verified';
		} else if (stageData[0]?.stageComplete || stageData[7]?.stageComplete) {
			return 'partially_verified';
		} else {
			return '';
		}
	};

	return (
		<section className='view-container'>
			<div className='identity-container' onClick={() => toggleDropdown()}>
				<p className='identity-header-title'>Right to Work and ID Verification</p>
				<div className='identity-header-right'>
					<ExternalUserProfileStatus
						sectionStatus={getSectionStatus()}
					/>
					<img
						className='chevron'
						src={cheveronDown}
						alt='arrow'
						style={{ transform: isOpen ? 'scaleY(-1)' : 'none' }}
					></img>
				</div>
			</div>
			<div style={collapsibleSectionStyle}>
				{(
					rightToWorkType !== 'none'
				) ? (
					<>{renderRtwSection()}</>
				) : (
					<section className='sub-section-container'>
						<div className='sub-section-title-container remove-pointer'>
							<p className='sub-section-title'>RIGHT TO WORK</p>
						</div>
						<div className='sub-section-non-submission-container '>
							<p>
								Your candidate has not submitted anything yet.
							</p>
						</div>
					</section>
				)}
				{!yotiDetails &&
					ambassadorCheckStatus &&
					ambassadorCheckStatus.status !== 404 &&
					ambassadorCheckStatus.status !== 'started' &&
					renderIdentitySection()}
				{/* To be incorporated once checkType available and Manager/Admin role access added in relevant endpoints */}
				{/* {rtwManualDocArray &&
					rtwManualDocArray.length > 0 &&
					applicant.nationalInsuranceNumber &&
					renderNISection()} */}
				{livenessCheck() && renderLivenessSection()}
			</div>
			<div className='last-verified'>
				{stageData[0]?.stageComplete || stageData[7]?.stageComplete
					? 'Last Verified on ' +
						formatDateToText(
							stageData[0].stageCompletedAt ||
								stageData[7].stageCompletedAt,
						)
					: ''}
			</div>
		</section>
	);
};

export default ExternalUserProfileIdentity;
