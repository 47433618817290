import React, { useState, FC, FormEvent } from 'react';
import './input.styles.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

type Props = {
	inputName: string;
	title: string;
	placeholder: string;
	value: any;
	emitValue?: any;
	handleBlur?: any;
	autocomplete?: string;
	type?: string;
	validationError?: string;
};

const EditInput: FC<Props> = ({
	inputName,
	title,
	placeholder,
	value,
	emitValue,
	handleBlur = null,
	autocomplete = 'on',
	type = 'text',
	validationError = '',
}) => {
	const [hasChanged, setHasChanged] = useState<boolean>(false);
	const handleChange = (e: FormEvent<HTMLInputElement>): void => {
		setHasChanged(true);
		emitValue(e.currentTarget.value, inputName);
	};

	const handleDateChange = (e: unknown): void => {
		emitValue(e, inputName);
	};

	const blur = (): void => {
		if (handleBlur && hasChanged) {
			handleBlur({ [inputName]: value });
		}
		setHasChanged(false);
	};

	return (
		<div className='edit-input-area'>
			<p className='orka-profile-label'>{title}</p>
			<div className='edit-input-display'>
				{!title.toLowerCase().match(/\bdate\b/gi) && (
					<div className='edit-input'>
						<input
							type={type}
							onChange={handleChange}
							value={value}
							placeholder={placeholder}
							onBlur={blur}
							autoComplete={autocomplete}
						/>
					</div>
				)}
				{title.toLowerCase().match(/\bdate\b/gi) && (
					<div className='edit-input-date'>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								format='DD/MM/YYYY'
								value={dayjs(value)}
								onChange={handleDateChange}
								// placeholder={placeholder}
								// KeyboardButtonProps={{
								// 	'aria-label': 'change date',
								// }}
								// keyboardIcon={
								// 	<CalendarTodayOutlinedIcon
								// 		fontSize='small'
								// 		color='primary'
								// 	/>
								// }
								className='edit-input-date-picker'
							/>
						</LocalizationProvider>
					</div>
				)}
			</div>
			{validationError && (
				<p className='validation-error'>{validationError}</p>
			)}
		</div>
	);
};

export default EditInput;
